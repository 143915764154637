import React, {Component} from 'react';
import Form from './form';
import ReferenceWorkflowAI from './reference_workflow_ai';
import Markdown from './markdown';
import {ReferenceStatuses} from '../constants.js.erb';
import request from '../request';


export default class ReferenceShow extends Component {
  constructor(props) {
    super(props);
    this.state = {show_summary: false};
    this.toggleAbstract = this.toggleAbstract.bind(this);
    this.toggleWorkflowData = this.toggleWorkflowData.bind(this);
  }

  toggleAbstract() {
    let show_abstract = !this.state.show_abstract;
    this.setState({show_abstract});
  }

  toggleWorkflowData() {
    let show_workflow_data = !this.state.show_workflow_data;
    this.setState({show_workflow_data});
  }

  renderNoAbstractText() {
    return (
      <div>
        This reference has no abstract text.
      </div>
    )
  }

  renderAbstract() {
    let {show_abstract} = this.state;
    if (!show_abstract) return null;
    let {reference} = this.props;
    return (
      <div>
        <Markdown text={reference.abstract_text} />
      </div>
    )
  }

  renderWorkflowData() {
    let {show_workflow_data} = this.state;
    if (!show_workflow_data) return null;
    let {reference} = this.props;
    return <ReferenceWorkflowAI reference_id={reference.id} />
  }

  renderDataButtons() {
    let {reference} = this.props;
    if (!reference.abstract_text) return this.renderNoAbstractText();
    let {show_abstract, show_workflow_data} = this.state;
    let workflow_btn_label = show_workflow_data ? ' Hide Workflow Data' : 'Show Workflow Data';
    let abstract_btn_label = show_abstract ? 'Hide Abstract' : 'Show Abstract';

    return (
      <div>
        <button className="btn btn-success ref-panel-btn" onClick={this.toggleAbstract}>{abstract_btn_label}</button>
        {this.renderAbstract()}

        <button className="btn btn-success ref-panel-btn" onClick={this.toggleWorkflowData}>{workflow_btn_label}</button>
        {this.renderWorkflowData()}
      </div>
    )
  }

  render() {
    let {reference} = this.props;
    return(
      <div className="row">
        <div className="col-md-9">
          {this.renderDataButtons()}
        </div>
        <div className="col-md-3">
          <RefereceStatusWidget reference={reference} />
        </div>
      </div>
    )
  }
}


class RefereceStatusWidget extends Component {
  constructor(props) {
    super(props);
    let reference = Object.assign({}, props.reference);
    this.state = {show_form: false, reference};

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
  }


  onSubmit() {
    let {reference} = this.state;
    let {status} = reference;
    let url = `/admin/references/${reference.id}/update_status`;

    this.setState({submitting: true});
    request('PUT', url, {reference: {status}}).then(reference => {
      this.setState({reference, submitting: false, show_form: false});
    })
  }

  onChange(reference) {
    this.setState({reference});
  }

  toggleForm() {
    let show_form = !this.state.show_form;
    this.setState({show_form});
  }

  renderForm() {
    let {reference, submitting} = this.state;
    let fields = [{name: 'status', type: 'select', label: 'Select New Status', collection: ReferenceStatuses}];
    return (
      <div className="reference_status_box">
        <Form model_name="status" fields={fields} model={reference} onChange={this.onChange} onSubmit={this.onSubmit} onCancel={this.toggleForm} submitting={submitting} />
      </div>
    )
  }

  renderStatus() {
    let {reference} = this.state;
    if (!reference.status) return null;

    return (
      <div id="reference_status" className="reference_status_box">
        <h4>Reference Status</h4>
        <div className="reference_status_details">
          <strong>{reference.status_humanized}</strong><br/>
          {reference.status_changed_at_humanized} by {reference.status_changed_by_name}
        </div>
        <button className="btn btn-default btn-sm" onClick={this.toggleForm}>Update Status</button>

      </div>
    );
  }

  render() {
    let {show_form} = this.state;
    if (show_form) {
      return this.renderForm();
    } else {
      return this.renderStatus();
    }
  }

}
